import PropTypes from 'prop-types'
import React from 'react'

import Item from './Item'

const Blog = (props) => {
  const { posts, theme } = props

  return (
    <React.Fragment>
      <section className="section">
        <ul id="post-list">
          {posts.map((post) => {
            const {
              node,
              node: {
                fields: { slug },
              },
            } = post
            return <Item key={slug} post={node} theme={theme} />
          })}
        </ul>
      </section>

      {/* --- STYLES --- */}
      <style jsx>{`
        .section {
          padding: 0 ${theme.space.inset.default};
        }

        ul {
          list-style: none;
          margin: 0 auto;
          padding: ${`calc(${theme.space.default} * 1.5) 0 calc(${theme.space.default} * 0.5)`};
        }

        @above tablet {
          .section {
            padding: 0 ${`0 calc(${theme.space.default} * 1.5)`};
          }
          ul {
            max-width: ${theme.text.maxWidth.tablet};
          }
        }
        @above desktop {
          ul {
            max-width: ${theme.text.maxWidth.desktop};
          }
        }
      `}</style>
    </React.Fragment>
  )
}

Blog.propTypes = {
  posts: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
}

export default Blog
